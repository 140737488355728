<template>
  <v-col class="px-0 py-0">
    <v-row no-gutters align="center" style="margin-top: 20px">
      <v-col class="py-0" style="margin-right: 10px">
        <statistic-card
          :numbers="statistic.total"
          title="Відгуків"
          subtitle="Написали клієнти"
        />
      </v-col>
      <v-col class="py-0" style="margin-left: 10px">
        <statistic-card
          :numbers="`${averageRating.toFixed(2)} / 5`"
          title="Середня оцінка"
          subtitle="Написали клієнти"
          :isReview="true"
          :statistic="statisticReview"
        />
      </v-col>
    </v-row>
    <v-row no-gutters style="margin-top: 40px">
      <div
        class="statusBox"
        :class="activeStatus == 'published' ? 'activeStatusBox' : ''"
        @click="activeStatus = 'published'"
      >
        Відгуки на сайті
      </div>
      <div
        class="statusBox"
        :class="activeStatus == 'new' ? 'activeStatusBox' : ''"
        @click="activeStatus = 'new'"
      >
        Нові відгуки
        <span
          v-if="activeStatus == 'new'"
          style="font-size: 16px; font-weight: 600"
          >{{ reviewsList.length }}</span
        >
      </div>
      <div
        class="statusBox"
        :class="activeStatus == 'archive' ? 'activeStatusBox' : ''"
        @click="activeStatus = 'archive'"
      >
        Архівні
      </div>
    </v-row>
    <div class="sectionBackground">
      <v-row no-gutters align="center">
        <p class="sectionTitle" style="margin-bottom: 0px">
          Відгуки наших клієнтів
        </p>
        <v-row no-gutters justify="end"
          ><p class="sectionText" style="margin-bottom: 0px">
            {{ reviewsList.length }}\20
          </p></v-row
        >
      </v-row>
      <loader v-if="showLoader" />
      <v-row
        v-else-if="reviewsList.length == 0"
        no-gutters
        justify="center"
        style="margin: 40px 0px"
      >
        <v-col cols="4">
          <p
            style="
              color: #1b1b1b;
              text-align: center;
              font-family: 'MacPaw Fixel';
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 0px;
            "
          >
            Відгуки не додані
          </p>
          <v-row no-gutters justify="center">
            <submit-button
              style="width: 220px; height: 48px !important; margin-top: 20px"
              @click="activeStatus = 'new'"
              >Дивитися нові відгуки</submit-button
            >
          </v-row>
        </v-col>
      </v-row>

      <draggable v-else v-model="reviewsList">
        <reviews-card
          v-for="review in reviewsList"
          :key="review.id"
          :review="review"
          :activeStatus="activeStatus"
          @addToMain="addToMain"
          @restoreReview="restoreReview"
          @deleteReview="deleteReview"
        />
      </draggable>
      <pagination
        :page="page"
        :quantityPage="quantityPage"
        @changePage="changePage"
      />
    </div>
    <submit-button
      style="
        margin-top: 40px;
        height: 48px !important;
        font-size: 20px;
        font-weight: 700;
      "
      @click="saveOrdering"
      >Зберегти зміни</submit-button
    >
  </v-col>
</template>

<script>
import StatisticCard from "../../../UI/Cards/statisticCard.vue";
import ReviewsCard from "./reviewsCard.vue";
import draggable from "vuedraggable";
import SubmitButton from "../../../UI/Buttons/submitButton.vue";
import reviewsService from "./../../../../requests/Admin/reviewsService.js";
import Loader from "../../../UI/Loader.vue";
import requestFormData from "../../../../requests/requestFormData";
import Pagination from "../../../UI/pagination.vue";
export default {
  components: {
    StatisticCard,
    ReviewsCard,
    draggable,
    SubmitButton,
    Loader,
    Pagination,
  },
  data: () => ({
    activeStatus: "published",
    statisticReview: [
      {
        rating: 5,
        percent: 50,
      },
      {
        rating: 4,
        percent: 20,
      },
      {
        rating: 3,
        percent: 20,
      },
      {
        rating: 2,
        percent: 5,
      },
      {
        rating: 1,
        percent: 5,
      },
    ],
    reviewsList: [],
    averageRating: 0,
    showLoader: true,
    statistic: {},
    page: 1,
    quantityPage: 0,
  }),
  mounted() {
    this.getStatistic();
    this.getReviews();
  },
  methods: {
    changePage(page) {
      this.page = page;
      this.showLoader = true;
      this.getReviews();
    },
    async getStatistic() {
      await reviewsService.getStatistic().then((res) => {
        if (res.status == "Success") {
          this.statistic = res.data;
          let rating = [];
          this.statistic.rating.forEach((statistic) => {
            for (let i = 0; i < statistic.count; i++) {
              rating.push(statistic.rate);
            }
          });
          this.averageRating =
            rating.reduce((acc, value) => (acc += value), 0) / rating.length ||
            0;
          this.statisticReview = this.convertToStatisticReview(
            this.statistic.rating
          );
        }
      });
    },
    async getReviews() {
      if (this.activeStatus == "published") {
        await reviewsService.getPublishedReviews(this.page).then((res) => {
          if (res.status == "Success") {
            this.reviewsList = res.data.sort((a, b) => a.order - b.order);
            this.quantityPage = res.pagination.meta.last_page;
          }
        });
      } else if (this.activeStatus == "new") {
        await reviewsService.getNewReviews(this.page).then((res) => {
          if (res.status == "Success") {
            this.reviewsList = res.data.filter(
              (review) => review.is_publish !== "Yes"
            );
            this.quantityPage = res.pagination.meta.last_page;
          }
        });
      } else {
        await reviewsService.getArchicedReviews(this.page).then((res) => {
          if (res.status == "Success") {
            this.reviewsList = res.data;
            this.quantityPage = res.pagination.meta.last_page;
          }
        });
      }
      this.showLoader = false;
    },
    async addToMain(id) {
      await reviewsService.addReviewToMain(id).then((res) => {
        if (res.status == "Success") {
          this.showLoader = true;
          this.getReviews();
        }
      });
    },
    convertToStatisticReview(data) {
      const ratingCounts = data;
      const totalCount = this.statistic.total;
      const statisticReview = [];

      for (let rating = 5; rating >= 1; rating--) {
        const count =
          ratingCounts.find((item) => item.rate == rating)?.count || 0;
        const percent = (count / totalCount) * 100;
        statisticReview.push({ rating, percent });
      }

      return statisticReview;
    },
    async deleteReview(id) {
      await reviewsService.deleteReview(id).then(() => {
        this.showLoader = true;
        this.getReviews();
      });
    },
    async restoreReview(id) {
      await reviewsService.restoreReview(id).then(() => {
        this.showLoader = true;
        this.getReviews();
      });
    },
    async saveOrdering() {
      this.reviewsList.forEach(async (review, index) => {
        const data = {
          message: review.message,
          rate: review.rate,
          lang: review.lang,
          usur_id: review.usur_id,
          order: index,
        };
        let form = requestFormData.jsonToFormData(data);
        await reviewsService.updateReview(review.id, form).then((res) => {
          if (res.status == "Success") {
            this.$emit("successCreate");
          }
        });
      });
    },
  },
  watch: {
    activeStatus: {
      handler() {
        this.showLoader = true;
        this.getReviews();
      },
    },
  },
};
</script>

<style>
</style>
StatisticCard