<template>
  <div class="reviewCardBackground">
    <v-row no-gutters align="center">
      <span class="reviewCardSectionName">Оцінка:</span>
      <span style="margin: 0px 8px">{{ review.rate }} / 5</span>
      <v-icon color="#FED500" size="20px" style="margin-bottom: 5px"
        >mdi-star</v-icon
      >
      <span
        class="reviewCardSectionName"
        style="margin-left: 32px; margin-right: 8px"
        >Маршрут:</span
      >
      <span style="color: #144fa9">Коломия - Краків</span>
      <span
        class="reviewCardSectionName"
        style="margin-left: 32px; margin-right: 8px"
        >Дата:</span
      >
      <span>{{
        new Date(review.created_at).toLocaleDateString("uk-UA", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
           timeZone: 'Europe/Kiev'
        })
      }}</span>
      <span
        class="reviewCardSectionName"
        style="margin-left: 32px; margin-right: 8px"
        >Клієнт:</span
      >
      <span style="color: #144fa9"
        >{{ review.user.first_name }} {{ review.user.last_name }}</span
      >
      <v-row no-gutters justify="end">
        <v-tooltip left v-if="activeStatus == 'new'">
          <template v-slot:activator="{ on, attrs }">
            <div
              class="iconBackground"
              style="margin-right: 12px"
              v-bind="attrs"
              v-on="on"
              @click="$emit('addToMain', review.id)"
            >
              <div class="adminPlusIcon" />
            </div>
          </template>
          Додати на сайт
        </v-tooltip>
        <div
          v-if="activeStatus == 'published'"
          class="iconBackground"
          style="margin-right: 12px"
          @click="$emit('addToMain', review.id)"
        >
          <div class="deleteIcon" />
        </div>
        <div
          v-if="activeStatus == 'new'"
          class="iconBackground"
          style="margin-right: 12px"
          @click="$emit('deleteReview', review.id)"
        >
          <div class="deleteIcon" />
        </div>
        <div
          v-if="activeStatus == 'archive'"
          class="iconBackground"
          @click="$emit('restoreReview', review.id)"
        >
          <v-icon color="#4b5262">mdi-restore</v-icon>
        </div>
        <div class="iconBackground" v-if="activeStatus == 'published'">
          <div class="dragIcon" />
        </div>
      </v-row>
    </v-row>
    <div class="sectionLine" style="margin: 20px 0px" />
    <p class="reviewCardSectionName" style="margin-bottom: 8px">Відгук</p>
    <span>{{ review.message }}</span>
  </div>
</template>

<script>
export default {
  props: {
    review: {
      require: true,
    },
    activeStatus: {
      require: true,
    },
  },
};
</script>

<style scoped>
.reviewCardBackground {
  padding: 16px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
}
.reviewCardSectionName {
  color: #4b5262;
}
</style>