<template>
  <div class="statisticCard">
    <h3 class="statisticCardNumbers">
      <ICountUp :delay="delay" :endVal="numbers" :options="options" />
    </h3>
    <v-col class="px-0 py-0" style="text-align: left">
      <p class="statisticCardTitle">
        {{ title }}
      </p>
      <span class="statisticCardSubtitle" v-if="!isReview">
        {{ subtitle }}
      </span>
      <v-row no-gutters align="center" v-else>
        <div
          style="text-align: center"
          :style="`width: ${fiveRating.percent}%`"
          v-if="fiveRating.percent !== 0"
        >
          <div
            style="
              border-radius: 4px 0px 0px 4px;
              background: #59a621;
              height: 12px;
            "
          ></div>
          <p class="ratingText">{{ fiveRating.rating }}/5</p>
        </div>
        <div
          style="text-align: center"
          :style="`width: ${fourRating.percent}%`"
          v-if="fourRating.percent !== 0"
        >
          <div style="background: #8ac450; height: 12px"></div>
          <p class="ratingText">{{ fourRating.rating }}/5</p>
        </div>
        <div
          style="text-align: center"
          :style="`width: ${threeRating.percent}%`"
          v-if="threeRating.percent !== 0"
        >
          <div style="background: #facb1b; height: 12px"></div>
          <p class="ratingText">{{ threeRating.rating }}/5</p>
        </div>
        <div
          style="text-align: center"
          :style="`width: ${twoRating.percent}%`"
          v-if="twoRating.percent !== 0"
        >
          <div style="background: #fa9676; height: 12px"></div>
          <p class="ratingText">{{ twoRating.rating }}/5</p>
        </div>
        <div
          style="text-align: center"
          :style="`width: ${oneRating.percent}%`"
          v-if="oneRating.percent !== 0"
        >
          <div
            style="
              border-radius: 0px 4px 4px 0px;
              background: #fe5c5a;
              height: 12px;
            "
          ></div>
          <p class="ratingText">{{ oneRating.rating }}/5</p>
        </div>
      </v-row>
      <div></div>
    </v-col>
  </div>
</template>

<script>
import ICountUp from "vue-countup-v2";
export default {
  components: {
    ICountUp,
  },
  data: () => ({
    delay: 500,
    options: {
      useEasing: true,
      useGrouping: true,
      separator: "",
      decimal: ".",
      prefix: "",
      suffix: "",
    },
    fiveRating: {},
    fourRating: {},
    threeRating: {},
    twoRating: {},
    oneRating: {},
  }),
  props: {
    numbers: {
      require: false,
    },
    title: {
      require: false,
    },
    subtitle: {
      require: false,
    },
    isReview: {
      require: false,
    },
    statistic: {
      type: Array,
      require: false,
    },
  },
  mounted() {
    this.setRating();
  },
  methods: {
    setRating() {
      if (this.statistic !== undefined) {
        this.fiveRating = this.statistic.find(
          (statistic) => statistic.rating == 5
        );
        this.fourRating = this.statistic.find(
          (statistic) => statistic.rating == 4
        );
        this.threeRating = this.statistic.find(
          (statistic) => statistic.rating == 3
        );
        this.twoRating = this.statistic.find(
          (statistic) => statistic.rating == 2
        );
        this.oneRating = this.statistic.find(
          (statistic) => statistic.rating == 1
        );
      }
    },
  },
  watch: {
    statistic: {
      deep: true,
      handler() {
        this.setRating();
      },
    },
  },
};
</script>

<style scoped>
.statisticCard {
  border-radius: 10px;
  background: #e7edf6;
  padding: 16px;
  height: max-content;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 100px;
}
.statisticCardNumbers {
  color: #144fa9;
  font-family: "MacPaw Fixel";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 20px;
}
.statisticCardTitle {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4px;
}
.statisticCardSubtitle {
  color: #989898;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ratingText {
  color: #4b5262;
  font-family: "MacPaw Fixel";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4px;
  margin-bottom: 0px;
}
</style>